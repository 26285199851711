/*
 * Copyright (C) Yağızhan IŞIK - 2022.
 * This app is developed for internal use only and may not be exported, shared, edited or distributed under any circumstances.
 */


let theFrame = document.querySelector("iframe");

// document.querySelector(".qc-new-income").addEventListener("click", () => {
// 	theFrame.src = "/frames/creates/receipt-income.html";
// });
// document.querySelector(".qc-new-expense").addEventListener("click", () => {
// 	theFrame.src = "/frames/creates/receipt-expense.html";
// });
// // document.querySelector(".qc-print-makbuz").addEventListener("click", () => {
// // 	theFrame.src = "/frames/queries/makbuz-list.html";
// // });
// document.querySelector(".parent-list").addEventListener("click", () => {
// 	theFrame.src = "/frames/queries/parent-list.html";
// });
// document.querySelector(".customer-list").addEventListener("click", () => {
// 	theFrame.src = "/frames/queries/child-list.html";
// });
// document.querySelector(".company-list").addEventListener("click", () => {
// 	theFrame.src = "/frames/queries/company-list.html";
// });
// document.querySelector(".paymentplan-list").addEventListener("click", () => {
// 	theFrame.src = "/frames/queries/pp-list.html";
// });
// document.querySelector(".dashboard-link").addEventListener("click", () => {
// 	theFrame.src = "/frames/dashboard/dashboard.html";
// });
// document.querySelector(".dashboard-link-alt").addEventListener("click", () => {
// 	theFrame.src = "/frames/dashboard/dashboard.html";
// });
// document.querySelector(".payments-list").addEventListener("click", () => {
// 	theFrame.src = "/frames/queries/makbuz-list.html";
// });
// document.querySelector(".car-manage").addEventListener("click", () => {
// 	theFrame.src = "/frames/queries/cars-list.html";
// });
// document.querySelector(".prereg-manage").addEventListener("click", () => {
// 	theFrame.src = "/frames/queries/prereg-query.html";
// });
// document.querySelector(".announce-mail").addEventListener("click", () => {
// 	// Mail Init
// 	sendNewMailSet(undefined, undefined, undefined, undefined);
// 	theFrame.src = "/frames/generic/custom-mail.html";
// });
// document.querySelector(".prereg-prefs").addEventListener("click", () => {
// 	theFrame.src = "/frames/pre-registrations/config.html";
// });
// document.querySelector(".download-desktop-client").addEventListener("click", () => {
// 	theFrame.src = "/frames/static/desktop-client.html";
// });
// document.querySelector(".qc-add-vehicle").addEventListener("click", () => {
// 	theFrame.src = "/frames/creates/car.html";
// });
// document.querySelector(".qc-missed-installments").addEventListener("click", () => {
// 	theFrame.src = "/frames/queries/missed-installments.html";
// });
// document.querySelector(".qc-expected-installments").addEventListener("click", () => {
// 	theFrame.src = "/frames/queries/expected-installments.html";
// });
// // document.querySelector(".qc-new-installment").addEventListener("click", () => {
// // 	theFrame.src = "/frames/creates/payment.html";
// // });
// document.querySelector(".qc-add-parent").addEventListener("click", () => {
// 	theFrame.src = "/frames/parent/create.html";
// });
document.querySelector(".log-out").addEventListener("click", () => {
	localStorage.removeItem('AppAccessToken');
	localStorage.removeItem('LocalCacheCompany');
	localStorage.removeItem('LocalCacheCrashReports');
	localStorage.removeItem('LocalCacheUserPrefs');
	location.href = "/";
 });
// document.querySelector(".smart-search").addEventListener("click", () => {
// 	theFrame.src = "/frames/search/smartsearch.html";
// });
// document.querySelector(".user-prefs").addEventListener("click", () => {
// 	theFrame.src = "/frames/generic/preferences.html";
// });

// const url_string = window.location.href;
// const url = new URL(url_string);
// const frt = url.searchParams.get("frtoken");

// if (localStorage.getItem('AFWelcomeCeremonyLock')) {
// 	// Yarım kalmış FRP
// 	if (frt && frt.length > 4) {
// 		localStorage.setItem('WCInvitationToken', frt);
// 	}
// 	window.history.pushState({}, '', 'app');
// 	theFrame.src = '/frames/welcome-ceremony/step1.html';
// } else if (frt) {
// 	// First Register Path
// 	window.history.pushState({}, '', 'app');
// 	localStorage.setItem('WCInvitationToken', frt);
// 	console.log(frt)
// 	localStorage.setItem('AFWelcomeCeremonyLock', 'true');
// 	theFrame.src = '/frames/welcome-ceremony/step1.html';
// }
let animationsEnabled = true;
if (localStorage.getItem('LocalCacheUserPrefs')) {
	let cachedPrefs = JSON.parse(localStorage.getItem('LocalCacheUserPrefs'));
	animationsEnabled = cachedPrefs.animationsEnabled;
}
if (animationsEnabled) {
	anime({
		targets: 'bx-header',
		translateY: [-50, 0],
		opacity: [0, 1],
		duration: 2000,
		easing: 'easeOutExpo',
		delay: 500,
	});
} else {
	anime({
		targets: 'bx-header',
		translateY: [0, 0],
		opacity: [1, 1],
		duration: 1,
		easing: 'easeOutExpo',
		delay: 0,
	});
}

document.querySelectorAll('bx-header-menu-item, bx-header-nav-item').forEach((elem) => {
	elem.addEventListener('click', () => {

	})
})

let healthCheckPassed = false;

setTimeout(() => {
	if (!healthCheckPassed) {
		location.href = '/frames/static/api-error.html';
	} else {
		console.log('Health Check Successful');
	}
}, API_TIMEOUT);

// Health Check

fetch(API_URL + '/health-check')
	.then(response => response.json())
	.then(data => {
		if (data.time || data.status === 404) {
			// healthy
			healthCheckPassed = true;
		}
	});


